import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_2 = ["href"]
const _hoisted_3 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_4 = ["href"]
const _hoisted_5 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MdIcon = _resolveComponent("MdIcon")!

  return (_openBlock(), _createElementBlock("tr", {
    style: _normalizeStyle([$props.style, {"cursor":"pointer"}]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.redirectTo(`/players?uuid=${$props.uuid}`)))
  }, [
    _createElementVNode("td", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString($props.name), 1),
        _createElementVNode("a", {
          href: $options.buildSearchURL($props.name)
        }, [
          _createVNode(_component_MdIcon, {
            icon: "search",
            classes: "clickable-icon",
            style: {"margin-left":"3px","padding":"5px"}
          })
        ], 8, _hoisted_2)
      ])
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString($props.uuid), 1),
        _createElementVNode("a", {
          href: $options.buildSearchURL($props.uuid)
        }, [
          _createVNode(_component_MdIcon, {
            icon: "search",
            classes: "clickable-icon",
            style: {"margin-left":"3px","padding":"5px"}
          })
        ], 8, _hoisted_4)
      ])
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", null, _toDisplayString($props.ip), 1),
        _createElementVNode("a", {
          href: $options.buildSearchURL($props.ip)
        }, [
          _createVNode(_component_MdIcon, {
            icon: "search",
            classes: "clickable-icon",
            style: {"margin-left":"3px","padding":"5px"}
          })
        ], 8, _hoisted_6)
      ])
    ]),
    _createElementVNode("td", null, _toDisplayString($options.toDateStringMaybe($props.lastLogin)), 1)
  ], 4))
}