<template>
  <div class="container">
    <slot />
  </div>
</template>

<style scoped>
.container {
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
