
import DropdownTrigger from '@/components/DropdownTrigger.vue'
import InputTextField from '@/components/InputTextField.vue'

const enterKeyListener = (e: KeyboardEvent) => {
  if (e.key !== 'Enter') return
  const searchBarActive = document.getElementById('search') === document.activeElement
  if (!searchBarActive) return
  location.href = `/search?q=${encodeURI((document.getElementById('search') as HTMLInputElement).value || '')}`
}

export default {
  components: {
    DropdownTrigger,
    InputTextField,
  },
  props: {
    loggedIn: Boolean,
    username: String,
    defaultSearchWord: String,
  },
  emits: [ 'search-input' ],
  methods: {
    fireInputEvent(event) {
      this.$emit('search-input', event)
    },
  },
  unmounted() {
    document.removeEventListener('keypress', enterKeyListener)
  },
  mounted() {
    document.addEventListener('keypress', enterKeyListener)
  },
}
