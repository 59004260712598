
import SLink from "@/components/SLink.vue"
import MdIcon from "@/components/MdIcon.vue"
import PunishmentType from '@/components/PunishmentType.vue'
import NumberToTime from '@/components/NumberToTime.vue'

export default {
  components: {
    NumberToTime,
    PunishmentType,
    MdIcon,
    SLink,
  },
  props: {
    id: Number,
    type: String,
    target: String,
    reason: String,
    duration: Number,
    server: String,
    unpunished: Boolean,
  },
}
