
export default {
  props: {
    ks: Number,
    vs: Number,
    k: String,
    v: String,
    kc: String,
    vc: String,
    vstyle: String,
  },
}
