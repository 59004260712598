import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-049fad43"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: $props.href,
    rel: "noopener",
    target: $props.openNewTab ? '_blank' : null,
    class: _normalizeClass($props.color),
    style: _normalizeStyle($props.style)
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 14, _hoisted_1))
}