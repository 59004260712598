import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11e3ddb5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["col", `s${$props.ks || 5}`])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(["flow-text", $props.kc]),
        style: {"text-align":"center"}
      }, [
        _createTextVNode(_toDisplayString($props.k) + " ", 1),
        _renderSlot(_ctx.$slots, "key", {}, undefined, true)
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["col", `s${$props.vs || 7}`]),
      style: _normalizeStyle($props.vstyle)
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(["flow-text", $props.vc])
      }, [
        _createTextVNode(_toDisplayString($props.v) + " ", 1),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2)
    ], 6)
  ]))
}