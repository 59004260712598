
import MdIcon from '@/components/MdIcon.vue'
import { buildSearchURL, search, toDateStringMaybe } from '@/util/util'

export default {
  components: { MdIcon },
  props: {
    name: String,
    uuid: String,
    lastLogin: Number,
    ip: String,
    style: Object,
  },
  methods: {
    buildSearchURL,
    search,
    toDateStringMaybe,
    redirectTo(url: string) {
      location.href = url
    },
  },
}
