import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MdIcon = _resolveComponent("MdIcon")!
  const _component_SLink = _resolveComponent("SLink")!
  const _component_NavWrapper = _resolveComponent("NavWrapper")!
  const _component_InputTextField = _resolveComponent("InputTextField")!
  const _component_ModalContent = _resolveComponent("ModalContent")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_ModalFooter = _resolveComponent("ModalFooter")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavWrapper, {
      "logged-in": $data.loggedIn,
      username: $data.username,
      "default-search-word": $props.defaultSearchWord,
      onSearchInput: $options.fireSearchInputEvent
    }, {
      common: _withCtx(() => [
        _createElementVNode("li", null, [
          _createVNode(_component_SLink, {
            href: "/punishments/new",
            style: {"display":"inline-flex","width":"100%"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MdIcon, { icon: "add" }),
              _createTextVNode("処罰を追加")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_SLink, { href: "/" }, {
            default: _withCtx(() => [
              _createTextVNode("処罰履歴")
            ]),
            _: 1
          })
        ])
      ]),
      menu: _withCtx(() => [
        _createElementVNode("li", null, [
          _createVNode(_component_SLink, { href: "/me" }, {
            default: _withCtx(() => [
              _createTextVNode("設定")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_SLink, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.doLogout()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("ログアウト")
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["logged-in", "username", "default-search-word", "onSearchInput"]),
    _createVNode(_component_Modal, {
      id: "login-modal",
      dismissible: $props.dismissibleLoginModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ModalContent, { title: "ログイン / 登録" }, {
          default: _withCtx(() => [
            _createVNode(_component_InputTextField, {
              "min-length": 5,
              type: "email",
              label: "メールアドレス",
              id: "navbar_email",
              refForRef: "email"
            }),
            _createVNode(_component_InputTextField, {
              "min-length": 7,
              type: "password",
              label: "パスワード",
              id: "navbar_password",
              refForRef: "password"
            }),
            _createVNode(_component_InputTextField, {
              "min-length": 6,
              "max-length": 10,
              type: "text",
              label: "(ログイン時+有効化してる人のみ)2FAのコードもしくは復旧コード",
              id: "navbar_2fa",
              refForRef: "mfa_token"
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ModalFooter, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              color: "blue accent-2",
              onClick: $options.loginWithDiscord,
              text: "Discordでログイン",
              disabled: $data.disableForm
            }, null, 8, ["onClick", "disabled"]),
            _createVNode(_component_v_btn, {
              color: "orange darken-4",
              onClick: $options.doRegister,
              text: "アカウントを作成",
              disabled: $data.disableForm
            }, null, 8, ["onClick", "disabled"]),
            _createVNode(_component_v_btn, {
              color: "green",
              onClick: $options.doLogin,
              text: "ログイン",
              disabled: $data.disableForm
            }, null, 8, ["onClick", "disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["dismissible"])
  ], 64))
}