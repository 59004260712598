
export default {
  props: {
    text: String,
    target: String,
  },
  mounted() {
    setTimeout(() => {
      // @ts-expect-error
      M.Dropdown.init(this.$refs.it, { // eslint-disable-line no-undef
        coverTrigger: false,
        hover: true,
      })
    }, 100)
  },
}
