import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d7df0604"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PunishmentType = _resolveComponent("PunishmentType")!
  const _component_NumberToTime = _resolveComponent("NumberToTime")!
  const _component_MdIcon = _resolveComponent("MdIcon")!
  const _component_SLink = _resolveComponent("SLink")!

  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass([$props.unpunished ? 'unpunished' : null])
  }, [
    _createElementVNode("td", null, "#" + _toDisplayString($props.id), 1),
    _createElementVNode("td", null, [
      _createVNode(_component_PunishmentType, { type: $props.type }, null, 8, ["type"])
    ]),
    _createElementVNode("td", null, _toDisplayString($props.target), 1),
    _createElementVNode("td", {
      style: {"white-space":"nowrap","text-overflow":"ellipsis","overflow":"hidden"},
      title: $props.reason
    }, _toDisplayString($props.reason), 9, _hoisted_1),
    _createElementVNode("td", null, [
      _createVNode(_component_NumberToTime, { time: $props.duration }, null, 8, ["time"])
    ]),
    _createElementVNode("td", null, _toDisplayString($props.server), 1),
    _createElementVNode("td", null, [
      _createVNode(_component_SLink, {
        color: "teal-but-green",
        href: `/punishments/view/?id=${$props.id}`
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MdIcon, {
            classes: "right clickable-icon",
            style: {"transform":"scale(1.15)","margin-right":"15px","padding":"3px","margin-left":"0"},
            icon: "article"
          })
        ]),
        _: 1
      }, 8, ["href"]),
      _createVNode(_component_SLink, {
        color: "teal-but-green",
        href: `/punishments/view/?id=${$props.id}&edit`
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MdIcon, {
            classes: "right clickable-icon",
            style: {"padding":"3px","margin-left":"0"},
            icon: "edit"
          })
        ]),
        _: 1
      }, 8, ["href"])
    ])
  ], 2))
}