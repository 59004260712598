import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d42b0d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "dropdown-trigger",
    "data-target": $props.target,
    ref: "it"
  }, _toDisplayString($props.text), 9, _hoisted_1))
}